package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ModulesString
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import it.neckar.open.collections.fastForEach
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.plus
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

interface InverterConfiguration : HasUuid {
  val inverterId: InverterId

  val inverterIndex: Int

  val mpptInputConfigurations: List<MpptInputConfiguration>


  val id: InverterConfigurationId
    get() = InverterConfigurationId(inverterId.uuid + inverterIndex)

  override val uuid: Uuid
    get() = id.uuid

  val numberOfOptimalModules: Int
    get() = mpptInputConfigurations.sumOf { it.numberOfOptimalModules }


  @Serializable
  data class MpptInputConfiguration(val inputIndex: Int, val stringConfigurations: List<StringConfiguration>) {

    val numberOfOptimalModules: Int
      get() = stringConfigurations.sumOf { it.optimalModuleCount ?: 0 }

    val hasLegalOptimalModuleCount: Boolean
      get() {
        val nonNullStringConfigurations = stringConfigurations.filter { it.optimalModuleCount != null }
        return nonNullStringConfigurations.all { stringConfiguration -> nonNullStringConfigurations.all { otherStringConfiguration -> otherStringConfiguration.optimalModuleCount == stringConfiguration.optimalModuleCount } }
      }

    fun forTheseRoofs(roofs: List<RoofId>): MpptInputConfiguration {
      return copy(stringConfigurations = stringConfigurations.map { it.forTheseRoofs(roofs) })
    }

    fun format(): String {
      return buildString {
        append("MPP-Eingang ${inputIndex + 1} (${'A' + inputIndex}): ${stringConfigurations.size} Strings")
        stringConfigurations.forEach { stringConfiguration ->
          append("\n")
          append(stringConfiguration.format())
        }
      }
    }
  }

  @Serializable
  data class StringConfiguration(val stringIndex: Int, val optimalModuleCount: Int?, val modulesStrings: List<ModulesString>?) {

    fun forTheseRoofs(roofs: List<RoofId>): StringConfiguration {
      return copy(modulesStrings = modulesStrings?.map { it.forTheseRoofs(roofs) })
    }

    fun format(): String {
      return buildString {
        append("String ${stringIndex + 1}")
        append(" - ${optimalModuleCount ?: "Keine"} Module (Optimal)")
        modulesStrings?.fastForEach { modulesStrings ->
          append("\n")
          append("${modulesStrings.numberOfModules} Module")
          append(" - ")
          append(modulesStrings.roofStrings.joinToString { roofString ->
            "${roofString.roofId}: ${roofString.numberOfModules} Module"
          })
        }
      }
    }
  }

  @Serializable
  data class InverterConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): InverterConfigurationId {
        return InverterConfigurationId(randomUuid4())
      }
    }
  }
}
