package it.neckar.lizergy.model.configuration.components

import com.benasher44.uuid.Uuid
import it.neckar.financial.quote.ConfiguredOptionality
import it.neckar.lizergy.model.ElementsSelectionEntry
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration.BatteryConfigurationId
import it.neckar.lizergy.model.configuration.components.FacilityConfiguration.FacilityConfigurationId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.configuration.quote.builder.BasicBatteryInverter
import it.neckar.lizergy.model.configuration.quote.builder.HeaterRod
import it.neckar.lizergy.model.configuration.quote.builder.HybridInverter
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.StringConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterSelection
import it.neckar.lizergy.model.configuration.quote.builder.InverterWithStringInputs
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.open.kotlin.lang.fastMap
import it.neckar.open.unit.si.m
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

interface FacilityConfiguration : HasUuid {
  val id: FacilityConfigurationId
  val numberOfOptimizers: Int
  val kabelwegZugschlagLength: @m Double
  val numberUeberspannungsSchutz: Int?

  val inverterSelection: InverterSelection
  val inverterConfigurations: List<InverterConfiguration>?
  val batteryConfigurationId: BatteryConfigurationId?

  val facilityWorkEffort: ConfigurationItem

  val waermepumpenanbindung: Boolean
  val erdspiessSetzen: Boolean
  val onlineMonitoring: ConfiguredOptionality
  val integrateInverterIntoNetwork: ConfiguredOptionality

  override val uuid: Uuid
    get() = id.uuid


  @Serializable
  data class FacilityConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): FacilityConfigurationId {
        return FacilityConfigurationId(randomUuid4())
      }
    }
  }

}

@Serializable
data class ResolvedFacilityConfiguration(
  override val id: FacilityConfigurationId,
  override val numberOfOptimizers: Int,
  override val kabelwegZugschlagLength: @m Double,
  override val numberUeberspannungsSchutz: Int?,

  override val inverterSelection: ResolvedInverterSelection,
  override val inverterConfigurations: List<ResolvedInverterConfiguration>,
  val batteryConfiguration: BatteryConfiguration?,

  override val facilityWorkEffort: ConfigurationItem,

  override val waermepumpenanbindung: Boolean,
  override val erdspiessSetzen: Boolean,
  override val onlineMonitoring: ConfiguredOptionality,
  override val integrateInverterIntoNetwork: ConfiguredOptionality,
  val heaterRod: HeaterRod?,
) : FacilityConfiguration {

  override val batteryConfigurationId: BatteryConfigurationId?
    get() = batteryConfiguration?.id

  val numberOfOptimalModules: Int
    get() = inverterConfigurations.sumOf { it.numberOfOptimalModules }

  fun forTheseRoofs(roofs: List<RoofId>): ResolvedFacilityConfiguration {
    return copy(inverterConfigurations = inverterConfigurations.map { it.forTheseRoofs(roofs) }.sortedBy { it.inverter.capacity })
  }

  fun updateStringConfigurationFor(inverter: InverterWithStringInputs, inverterIndex: Int, inputIndex: Int, stringIndex: Int, update: StringConfiguration.() -> StringConfiguration): ResolvedFacilityConfiguration {
    val updatedInverterConfigurations = inverterConfigurations.map { inverterConfiguration ->
      if (inverterConfiguration.inverter == inverter && inverterConfiguration.inverterIndex == inverterIndex) {
        inverterConfiguration.updateStringConfigurationFor(inputIndex, stringIndex, update)
      } else {
        inverterConfiguration
      }
    }
    return copy(inverterConfigurations = updatedInverterConfigurations)
  }

  fun recalculateInverterConfigurations(): ResolvedFacilityConfiguration {
    return ResolvedFacilityConfiguration(
      id = id,
      numberOfOptimizers = numberOfOptimizers,
      kabelwegZugschlagLength = kabelwegZugschlagLength,
      numberUeberspannungsSchutz = numberUeberspannungsSchutz,
      inverterSelection = inverterSelection,
      batteryConfiguration = batteryConfiguration,
      facilityWorkEffort = facilityWorkEffort,
      waermepumpenanbindung = waermepumpenanbindung,
      erdspiessSetzen = erdspiessSetzen,
      onlineMonitoring = onlineMonitoring,
      integrateInverterIntoNetwork = integrateInverterIntoNetwork,
      heaterRod = heaterRod,
    )
  }

  fun invalidManagerCombination(electricityWorkConfiguration: ResolvedElectricityWorkConfiguration): Boolean? {
    return electricityWorkConfiguration.invalidManagerCombination(inverterSelection, batteryConfiguration?.inverterType)
  }

  fun duplicate(mapOfOldToNewUuids: MutableMap<Uuid, Uuid>): ResolvedFacilityConfiguration {
    val newId = FacilityConfigurationId.random()
    mapOfOldToNewUuids[id.uuid] = newId.uuid
    return copy(
      id = newId,
      inverterSelection = inverterSelection.duplicate(mapOfOldToNewUuids),
    )
  }

  companion object {
    operator fun invoke(
      id: FacilityConfigurationId,
      numberOfOptimizers: Int,
      kabelwegZugschlagLength: @m Double,
      numberUeberspannungsSchutz: Int?,
      inverterSelection: ResolvedInverterSelection,
      batteryConfiguration: BatteryConfiguration?,
      facilityWorkEffort: ConfigurationItem,
      waermepumpenanbindung: Boolean,
      erdspiessSetzen: Boolean,
      onlineMonitoring: ConfiguredOptionality,
      integrateInverterIntoNetwork: ConfiguredOptionality,
      heaterRod: HeaterRod?,
    ): ResolvedFacilityConfiguration {
      val inverterSelections = when (val batteryInverter = batteryConfiguration?.inverterType) {
        is HybridInverter -> inverterSelection.entriesNonEmpty + ElementsSelectionEntry(batteryInverter, 1)
        is BasicBatteryInverter, null -> inverterSelection.entriesNonEmpty
      }

      val inverterConfigurations = inverterSelections.flatMap { selectionEntry ->
        selectionEntry.amount.fastMap { inverterIndex -> ResolvedInverterConfiguration(selectionEntry.element, inverterIndex) }
      }

      return ResolvedFacilityConfiguration(
        id = id,
        numberOfOptimizers = numberOfOptimizers,
        kabelwegZugschlagLength = kabelwegZugschlagLength,
        numberUeberspannungsSchutz = numberUeberspannungsSchutz,
        inverterSelection = inverterSelection,
        inverterConfigurations = inverterConfigurations,
        batteryConfiguration = batteryConfiguration,
        facilityWorkEffort = facilityWorkEffort,
        waermepumpenanbindung = waermepumpenanbindung,
        erdspiessSetzen = erdspiessSetzen,
        onlineMonitoring = onlineMonitoring,
        integrateInverterIntoNetwork = integrateInverterIntoNetwork,
        heaterRod = heaterRod,
      )
    }


    fun getEmpty(id: FacilityConfigurationId = FacilityConfigurationId.random()): ResolvedFacilityConfiguration {
      return ResolvedFacilityConfiguration(
        id = id,
        numberOfOptimizers = 0,
        kabelwegZugschlagLength = 0.0,
        numberUeberspannungsSchutz = null,
        inverterSelection = ResolvedInverterSelection.getEmpty(),
        batteryConfiguration = null,
        facilityWorkEffort = ConfigurationItem.risikoZuschlag,
        waermepumpenanbindung = false,
        erdspiessSetzen = false,
        onlineMonitoring = ConfiguredOptionality.Selected,
        integrateInverterIntoNetwork = ConfiguredOptionality.NotSelected,
        heaterRod = null,
      )
    }

    fun presentationDemo(id: FacilityConfigurationId = FacilityConfigurationId.random(), inverterSelection: ResolvedInverterSelection, batteryConfiguration: BatteryConfiguration?): ResolvedFacilityConfiguration {
      return ResolvedFacilityConfiguration(
        id = id,
        numberOfOptimizers = 0,
        kabelwegZugschlagLength = 15.0,
        numberUeberspannungsSchutz = 1,
        batteryConfiguration = batteryConfiguration,
        inverterSelection = inverterSelection,
        facilityWorkEffort = ConfigurationItem.risikoZuschlag,
        waermepumpenanbindung = false,
        erdspiessSetzen = false,
        onlineMonitoring = ConfiguredOptionality.Selected,
        integrateInverterIntoNetwork = ConfiguredOptionality.NotSelected,
        heaterRod = null,
      )
    }
  }
}
