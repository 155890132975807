package components.accounting

import components.form.commentSection
import components.project.LocalStorageKeys
import components.project.ResolvedProjectPreviewsTable
import components.project.projectsFiltering
import it.neckar.commons.kotlin.js.LocalStorageKey
import it.neckar.commons.kotlin.js.LocalStorageKeyPrefix
import it.neckar.commons.kotlin.js.LocalStorageSupport
import it.neckar.customer.company.CompanyCode
import it.neckar.customer.company.CompanyProfile
import it.neckar.customer.company.MainCompanyProfile
import it.neckar.customer.company.NeckarITCompanyProfile
import it.neckar.customer.company.PartnerCompanyProfile
import it.neckar.customer.company.TestCompanyProfile
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.company.CompanyResolver
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.lizergy.model.price.AccountingStatus
import it.neckar.lizergy.model.price.ResolvedEarningsEntry
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedConfigurationPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedProjectPreview
import it.neckar.lizergy.model.project.process.state.LizergyProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry.ProjectProcessStates
import it.neckar.open.kotlin.lang.divFloor
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import it.neckar.react.common.router.*
import it.neckar.react.common.table.*
import it.neckar.user.UserLoginName
import react.*
import react.dom.*
import router.RouterUrls
import router.useDocumentTitle
import services.UiActions
import services.http.AccountingQuerySelection
import services.http.CompanyRevenue
import services.http.ProjectQuerySortedBy
import services.http.ProjectQuerySortedColumn
import services.http.ProjectQuerySorting
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver

val AccountingOverview2: FC<Props> = fc("AccountingOverview2") {
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()
  val companyName = useRequireCompanyForLoggedInUser().simpleName

  useDocumentTitle(companyName, "Abrechnung")

  val keyPrefix = LocalStorageKeyPrefix("Accounting2")

  val filteredByCompanyCode: StateInstance<CompanyCode?> = useState(null)

  // If the value is null, all projects shall be shown.
  val filteredByMaintainerName: StateInstance<UserLoginName?> = useState(
    LocalStorageSupport.loadFromLocalStorage(
      key = LocalStorageKey("${keyPrefix}${LocalStorageKeys.maintainer}"),
      serializer = UserLoginName.serializer()
    )
  )

  // If the value is null, all projects shall be shown.
  val filteredByEditorName: StateInstance<UserLoginName?> = useState(
    LocalStorageSupport.loadFromLocalStorage(
      key = LocalStorageKey("${keyPrefix}${LocalStorageKeys.maintainer}"),
      serializer = UserLoginName.serializer()
    )
  )

  val filteredByProjectState: StateInstance<LizergyProcessStates?> = useState(null) //default: show all projects - but not archived!

  val filterInputStateProject = useState("")
  val filterInputStateAddress = useState("")

  val sortedByFunction = useState(
    SortedByFunction(
      sortedBy = SortedBy.SortedDescending,
      tableDataColumn = ResolvedProjectPreviewsTable.getProcessStateColumn<AccountingResolvedProjectPreview>(null),
    )
  )

  val pageSize: StateInstance<Int?> = useState(15)
  val currentPage = useState(0)

  val visibleProjects: StateInstance<List<AccountingResolvedProjectPreview>?> = useState(null)
  val numberOfFilteredProjects: StateInstance<Int?> = useState(null)
  val numberOfRelevantProjects: StateInstance<Int?> = useState(null)

  val lizergyRevenue: StateInstance<CompanyRevenue?> = useState(null)
  val neckarITRevenue: StateInstance<CompanyRevenue?> = useState(null)
  val partnerRevenues: StateInstance<List<CompanyRevenue>?> = useState(null)

  val accountingQuerySelection = useState(AccountingQuerySelection.SelectedForAccounting)
  val howDoYouLikeYourQuoteElements = useState(AccountingProjectPreview.QuoteElements.Frozen)


  useEffect(
    filteredByCompanyCode.value,
    filteredByMaintainerName.value,
    filteredByEditorName.value,
    filterInputStateProject.value,
    filterInputStateAddress.value,
    filteredByProjectState.value,
    sortedByFunction.value,
    pageSize.value,
    currentPage.value,
    accountingQuerySelection.value,
    howDoYouLikeYourQuoteElements.value,
  ) {
    val sorting = ProjectQuerySorting(
      sortedBy = when (sortedByFunction.value.sortedBy) {
        SortedBy.Unsorted -> ProjectQuerySortedBy.Unsorted
        SortedBy.SortedAscending -> ProjectQuerySortedBy.SortedAscending
        SortedBy.SortedDescending -> ProjectQuerySortedBy.SortedDescending
      },
      sortedColumn = when (sortedByFunction.value.sortedColumn.id) {
        "project" -> ProjectQuerySortedColumn.ProjectColumn
        "accountingTable" -> ProjectQuerySortedColumn.AccountingTable
        "processState" -> ProjectQuerySortedColumn.ProcessStateColumn
        else -> throw IllegalStateException("Unknown sortedColumn: ${sortedByFunction.value.sortedColumn.id}")
      },
    )

    visibleProjects.setter(null)
    numberOfFilteredProjects.setter(null)
    numberOfRelevantProjects.setter(null)

    UiActions.accountingQueryProjectPreviews(
      processStatesToFilter = LizergyProcessStates.allAccountingProcessStates,
      processStatesToHide = listOf(ProjectProcessStates.Archived, ProjectProcessStates.Paused),
      accountingQuerySelection = accountingQuerySelection.value,
      filteredByCompanyCode = filteredByCompanyCode.value,
      filteredByMaintainer = filteredByMaintainerName.value,
      filteredByEditor = filteredByEditorName.value,
      filterValueProject = filterInputStateProject.value,
      filterValueAddress = filterInputStateAddress.value,
      filterByProjectState = filteredByProjectState.value,
      indexOfFirstVisibleProject = pageSize.value?.let { currentPage.value * it } ?: 0,
      indexOfLastVisibleProject = pageSize.value?.let { (currentPage.value + 1) * it } ?: Int.MAX_VALUE,
      sorting = sorting,
      projectQueryComponent = null,
      howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements.value,
      loggedInUser = loggedInUser.loginName,
    ) { projectQuery ->
      val queryInformation = projectQuery?.data
      visibleProjects.setter(queryInformation?.visibleProjectPreviews)
      numberOfFilteredProjects.setter(queryInformation?.numberOfTotalFilteredProjects)
      numberOfRelevantProjects.setter(queryInformation?.numberOfTotalRelevantProjects)
      currentPage.setter(currentPage.value.coerceIn(0, queryInformation?.numberOfTotalFilteredProjects?.divFloor(pageSize.value ?: 1)))

      lizergyRevenue.setter(queryInformation?.lizergyRevenue)
      neckarITRevenue.setter(queryInformation?.neckarITRevenue)
      partnerRevenues.setter(queryInformation?.partnerRevenues)
    }
  }


  div {

    h2 {
      +"Abrechnung"
    }

    RevenueOverview {
      attrs {
        this.lizergyRevenue = lizergyRevenue.value
        this.neckarITRevenue = neckarITRevenue.value
        this.partnerRevenues = partnerRevenues.value
      }
    }

    div {

      projectsFiltering(
        filteredByCompanyCode = filteredByCompanyCode,
        filteredByMaintainerName = filteredByMaintainerName,
        filteredByEditorName = filteredByEditorName,
        filteredByProjectState = filteredByProjectState,
        filterInputStateProject = filterInputStateProject,
        filterInputStateAddress = filterInputStateAddress,
        processStatesToFilter = LizergyProcessStates.allAccountingProcessStates,
        localStorageKeyPrefix = keyPrefix,
      )

      div("row g-2 mt-2 mb-3") {
        div("col") {
          floatingSelectEnum(
            valueAndSetter = accountingQuerySelection,
            formatter = {
              when (it) {
                AccountingQuerySelection.All -> "ALLE"
                AccountingQuerySelection.PendingForAccounting -> "Ausstehend"
                AccountingQuerySelection.SelectedForAccounting -> "Gebucht"
                AccountingQuerySelection.Accounted -> "Abgerechnet"
              }
            },
            availableOptions = listOf(AccountingQuerySelection.SelectedForAccounting, AccountingQuerySelection.Accounted),
            fieldName = "accountingAccountingQuerySelection",
            title = "Projektauswahl",
          )
        }

        div("col") {
          floatingSelectEnum(
            valueAndSetter = howDoYouLikeYourQuoteElements,
            formatter = {
              when (it) {
                AccountingProjectPreview.QuoteElements.Frozen -> "Nur eingefrorene Preise"
                AccountingProjectPreview.QuoteElements.UpToDate -> "Nur aktuelle Preise"
                AccountingProjectPreview.QuoteElements.Whatever -> "Aktuelle Preise, falls Eingefrorene nicht vorhanden"
              }
            },
            availableOptions = AccountingProjectPreview.QuoteElements.entries,
            fieldName = "accountingHowDoYouLikeYourQuoteElements",
            title = "Preise",
          )
        }
      }

      busyIfNull(visibleProjects.value) { loadedProjectPreviews ->
        remoteTableWithPagination(
          tableClasses = "table table-striped table-responsive",
          entries = loadedProjectPreviews,
          columns = buildList {
            add(ResolvedProjectPreviewsTable.editButtonFirstColumn { navigate(RouterUrls.project(it).configuration((it.currentConfigurationPreview ?: return@editButtonFirstColumn).configurationId).earnings) })
            add(getAccountingProjectColumnWithComments(companyResolver))
            add(accountingTable(howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements.value))
            add(ResolvedProjectPreviewsTable.getMostRecentProcessStateColumn())
          },
          pageSize = pageSize,
          currentPage = currentPage,
          numberOfTotalFilteredProjects = numberOfFilteredProjects.value ?: 0,
          sortedByFunction = sortedByFunction,
        )
      }

      div("mt-1") {
        span("form-text") {
          +"${numberOfFilteredProjects.value ?: "-"} von ${numberOfRelevantProjects.value ?: "-"} Projekten ausgewählt"
        }
      }

    }

  }

}


data class AccountingTableEntries(
  val companyProfile: CompanyProfile,
  val configurationPreview: AccountingResolvedConfigurationPreview,
  val earningsCompanyEntries: List<EarningsCompanyEntryAndStates>,
) {
  val earningsCompanyEntriesByCompany: Map<CompanyCode, List<EarningsCompanyEntryAndStates>>
    get() = earningsCompanyEntries.groupBy { it.earningsCompanyEntry.company }

  val relevantEarningsCompanyEntries: List<EarningsCompanyEntryAndStates>
    get() = when (companyProfile) {
      is MainCompanyProfile -> emptyList()
      NeckarITCompanyProfile, is PartnerCompanyProfile -> earningsCompanyEntriesByCompany[companyProfile.companyCode] ?: emptyList()
      is TestCompanyProfile -> emptyList()
    }

  val openEarningsCompanyEntries: List<EarningsCompanyEntryAndStates>
    get() = relevantEarningsCompanyEntries.filter { it.accountingStatusState.value == AccountingStatus.Pending }

  fun relevantEarnings(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return when (companyProfile) {
      is MainCompanyProfile -> configurationPreview.getEarningsForMainCompany(howDoYouLikeYourQuoteElements)
      is PartnerCompanyProfile, is TestCompanyProfile -> configurationPreview.getEarningsForCompany(companyProfile.companyCode, howDoYouLikeYourQuoteElements)
      NeckarITCompanyProfile -> configurationPreview.getNeckarITEarnings(howDoYouLikeYourQuoteElements)
    }
  }
}

data class EarningsCompanyEntryAndStates(
  val earningsCompanyEntry: ResolvedEarningsEntry,
  val incomeState: StateInstance<Double?>,
  val accountingStatusState: StateInstance<AccountingStatus>,
)

data class IncomePercentageCategoryAndState(
  val incomePercentageCategory: IncomePercentageCategory,
  val incomeState: StateInstance<Double?>,
)


fun getAccountingProjectColumnWithComments(companyResolver: CompanyResolver): TableDataColumn<AccountingResolvedProjectPreview> {
  return TableDataColumn(
    id = "project",
    title = "Projekt",
    sortFunction = compareBy { it.displayName },
  ) { projectPreview ->
    {
      accountingProjectColumnDetails(projectPreview, companyResolver)
      div("my-4") {
        projectPreview.currentConfigurationPreview?.earningsDistribution?.let { commentSection(it) }
      }
    }
  }
}
