@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import it.neckar.lifeCycle.HasLifeCycle
import it.neckar.lifeCycle.LifeCycleState
import it.neckar.lizergy.model.configuration.energy.Capacity
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.MppInput
import it.neckar.open.kotlin.lang.fastFor
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
sealed interface InverterType : HasUuid, HasLifeCycle {
  val id: InverterId

  val description: String

  val phase: Phase

  override val uuid: Uuid
    get() = id.uuid

  @Serializable
  enum class Phase {
    SinglePhase,
    ThreePhase,

    ;

    override fun toString(): String {
      return when (this) {
        SinglePhase -> "einphasig"
        ThreePhase -> "dreiphasig"
      }
    }
  }

  @Serializable
  data class MppInput(val inputIndex: Int, val strings: List<StringMppInput>) {
    fun format(): String {
      return buildString {
        append("MPP-Eingang ${inputIndex + 1} (${'A' + inputIndex}): ")
        append("${strings.size} ${if (strings.size == 1) "String" else "Strings"}")
      }
    }
  }

  @Serializable
  data class StringMppInput(val stringIndex: Int)

  fun format(): String

  /**
   * An id for an inverter type
   */
  @Serializable
  data class InverterId(val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): InverterId {
        return InverterId(randomUuid4())
      }
    }
  }
}

@Serializable
sealed interface InverterWithStringInputs : InverterType {
  val capacity: Capacity

  /**
   * Maximum power point tracking
   */
  val mppInputs: List<MppInput>

  val numberOfMppInputs: Int
    get() = mppInputs.size
}

@Serializable
sealed interface BatteryInverter : InverterType {
  val dischargeCapacity: Capacity
}


@Serializable
data class Inverter(
  override val id: InverterId,
  override val description: String,
  override val capacity: Capacity,
  override val phase: InverterType.Phase,
  /**
   * Maximum power point tracking
   */
  override val mppInputs: List<MppInput>,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : InverterWithStringInputs {

  override fun format(): String {
    return buildString {
      append(description)
      append(", Leistung: ${capacity.format()}")
      append(", $phase")
    }
  }

  companion object {
    operator fun invoke(id: InverterId, description: String, capacity: Capacity, phase: InverterType.Phase, numberOfStringsForMppTrackerInput: List<Int>, lifeCycleState: LifeCycleState = LifeCycleState.Active): Inverter {
      val mppInputs = numberOfStringsForMppTrackerInput.mapIndexed { index, numberOfStrings ->
        val strings = buildList { numberOfStrings.fastFor { index -> add(InverterType.StringMppInput(index)) } }
        MppInput(index, strings)
      }
      return Inverter(id, description, capacity, phase, mppInputs, lifeCycleState)
    }
  }
}

@Serializable
data class HybridInverter(
  override val id: InverterId,
  override val description: String,
  override val capacity: Capacity,
  override val dischargeCapacity: Capacity,
  override val phase: InverterType.Phase,
  /**
   * Maximum power point tracking
   */
  override val mppInputs: List<MppInput>,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : BatteryInverter, InverterWithStringInputs {

  override fun format(): String {
    return buildString {
      append(description)
      append(", Leistung: ${capacity.format()}")
      dischargeCapacity.let { append(", Entladeleistung: ${it.format()}") }
      append(", $phase")
    }
  }

  companion object {
    operator fun invoke(id: InverterId, description: String, capacity: Capacity, dischargeCapacity: Capacity, phase: InverterType.Phase, numberOfStringsForMppTrackerInput: List<Int>, lifeCycleState: LifeCycleState = LifeCycleState.Active): HybridInverter {
      val mppInputs = numberOfStringsForMppTrackerInput.mapIndexed { index, numberOfStrings ->
        val strings = buildList { numberOfStrings.fastFor { index -> add(InverterType.StringMppInput(index)) } }
        MppInput(index, strings)
      }
      return HybridInverter(id, description, capacity, dischargeCapacity, phase, mppInputs, lifeCycleState)
    }
  }
}

@Serializable
data class BasicBatteryInverter(
  override val id: InverterId,
  override val description: String,
  override val dischargeCapacity: Capacity,
  override val phase: InverterType.Phase,
  override val lifeCycleState: LifeCycleState = LifeCycleState.Active,
) : BatteryInverter {
  override fun format(): String {
    return buildString {
      append(description)
      append(", Entladeleistung: ${dischargeCapacity.format()}")
      append(", $phase")
    }
  }
}
