package components.accounting

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.lizergy.model.price.AccountingStatus
import it.neckar.lizergy.model.price.ResolvedCompanyEarningsEntry
import it.neckar.lizergy.model.price.ResolvedNeckarITEarningsEntry
import it.neckar.lizergy.model.project.previews.AccountingProjectPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedConfigurationPreview
import it.neckar.lizergy.model.project.previews.AccountingResolvedProjectPreview
import it.neckar.open.collections.fastForEach
import it.neckar.open.formatting.dateFormat
import it.neckar.open.formatting.format
import it.neckar.open.formatting.formatAsPercentage
import it.neckar.open.kotlin.lang.percent
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.form.*
import it.neckar.react.common.table.*
import kotlinx.html.DIV
import kotlinx.html.InputType
import kotlinx.html.title
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver

fun accountingTable(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): TableDataColumn<AccountingResolvedProjectPreview> {
  return TableDataColumn(
    id = "accountingTable",
    title = "Abrechnung",
    sortFunction = compareBy { it.currentConfigurationPreview?.getNetPrice(howDoYouLikeYourQuoteElements) },
  ) { projectPreview ->
    {
      val currentConfigurationPreview = projectPreview.currentConfigurationPreview
      if (currentConfigurationPreview != null) {
        AccountingTable {
          attrs {
            this.projectPreview = projectPreview
            this.currentConfigurationPreview = currentConfigurationPreview
            this.howDoYouLikeYourQuoteElements = howDoYouLikeYourQuoteElements
          }
        }
      } else {
        p("form-text") {
          +"Kein Angebot vorhanden"
        }
      }
    }
  }
}

val AccountingTable: FC<AccountingTableProps> = fc("AccountingTable") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()

  val projectPreview = props::projectPreview.safeGet()
  val currentConfigurationPreview = props::currentConfigurationPreview.safeGet()
  val howDoYouLikeYourQuoteElements = props::howDoYouLikeYourQuoteElements.safeGet()

  val earningsDistribution = currentConfigurationPreview.earningsDistribution
  val manualQuoteElements = currentConfigurationPreview.manualQuoteElements

  val tippgeberState = useState(earningsDistribution.tippgeber.accountingStatus)
  val vertriebProjekterfassungState = useState(earningsDistribution.vertriebProjekterfassung.accountingStatus)
  val vertriebAngebotsvorstellungState = useState(earningsDistribution.vertriebAngebotsvorstellung.accountingStatus)
  val technischePlanungDachState = useState(earningsDistribution.technischePlanungDach.accountingStatus)
  val technischePlanungElektrikState = useState(earningsDistribution.technischePlanungElektrik.accountingStatus)
  val montageDachState = useState(earningsDistribution.montageDach.accountingStatus)
  val montageGeruestState = useState(earningsDistribution.montageGeruest.accountingStatus)
  val elektroInstallationState = useState(earningsDistribution.elektroInstallation.accountingStatus)
  val netzvoranfrageState = useState(earningsDistribution.netzvoranfrage.accountingStatus)
  val neckarITAccountingStatusState = useState(earningsDistribution.neckarITAccountingStatus.accountingStatus)

  val manualTippgeberEarnings = useState(earningsDistribution.tippgeber.manualEarnings?.euros)
  val manualVertriebProjekterfassungEarnings = useState(earningsDistribution.vertriebProjekterfassung.manualEarnings?.euros)
  val manualVertriebAngebotsvorstellungEarnings = useState(earningsDistribution.vertriebAngebotsvorstellung.manualEarnings?.euros)
  val manualTechnischePlanungDachEarnings = useState(earningsDistribution.technischePlanungDach.manualEarnings?.euros)
  val manualTechnischePlanungElektrikEarnings = useState(earningsDistribution.technischePlanungElektrik.manualEarnings?.euros)
  val manualMontageDachEarnings = useState(earningsDistribution.montageDach.manualEarnings?.euros)
  val manualMontageGeruestEarnings = useState(earningsDistribution.montageGeruest.manualEarnings?.euros)
  val manualElektroInstallationEarnings = useState(earningsDistribution.elektroInstallation.manualEarnings?.euros)
  val manualNetzvoranfrageEarnings = useState(earningsDistribution.netzvoranfrage.manualEarnings?.euros)
  val manualNeckarITEarnings = useState(earningsDistribution.neckarITAccountingStatus.manualEarnings?.euros)

  val manualProfit1 = useState(manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit1]?.currentValue?.euros)
  val manualProfit2Battery = useState(manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit2Battery]?.currentValue?.euros)
  val manualProfit3Scaffolding = useState(manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit3Scaffolding]?.currentValue?.euros)
  val manualProfit4ElectricityMaterial = useState(manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit4ElectricityMaterial]?.currentValue?.euros)
  val manualProfit5Assembly = useState(manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit5Assembly]?.currentValue?.euros)
  val manualProfit6ElectricityWork = useState(manualQuoteElements.manualSumsForTags[IncomePercentageCategory.Profit6ElectricityWork]?.currentValue?.euros)

  val manualQuoteElementsMap = buildMap {
    put(IncomePercentageCategory.Profit1, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit1, manualProfit1))
    put(IncomePercentageCategory.Profit2Battery, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit2Battery, manualProfit2Battery))
    put(IncomePercentageCategory.Profit3Scaffolding, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit3Scaffolding, manualProfit3Scaffolding))
    put(IncomePercentageCategory.Profit4ElectricityMaterial, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit4ElectricityMaterial, manualProfit4ElectricityMaterial))
    put(IncomePercentageCategory.Profit5Assembly, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit5Assembly, manualProfit5Assembly))
    put(IncomePercentageCategory.Profit6ElectricityWork, IncomePercentageCategoryAndState(IncomePercentageCategory.Profit6ElectricityWork, manualProfit6ElectricityWork))
  }


  val tippgeberToSave = useMemo(tippgeberState.value, manualTippgeberEarnings.value) {
    earningsDistribution.tippgeber.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualTippgeberEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.tippgeber.accountingStatusHistory.with(tippgeberState.value, loggedInUser.loginName),
    )
  }

  val vertriebProjekterfassungToSave = useMemo(vertriebProjekterfassungState.value, manualVertriebProjekterfassungEarnings.value) {
    earningsDistribution.vertriebProjekterfassung.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualVertriebProjekterfassungEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.vertriebProjekterfassung.accountingStatusHistory.with(vertriebProjekterfassungState.value, loggedInUser.loginName),
    )
  }

  val vertriebAngebotsvorstellungToSave = useMemo(
    vertriebAngebotsvorstellungState.value,
    manualVertriebAngebotsvorstellungEarnings.value,
  ) {
    earningsDistribution.vertriebAngebotsvorstellung.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualVertriebAngebotsvorstellungEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.vertriebAngebotsvorstellung.accountingStatusHistory.with(vertriebAngebotsvorstellungState.value, loggedInUser.loginName),
    )
  }

  val technischePlanungDachToSave = useMemo(technischePlanungDachState.value, manualTechnischePlanungDachEarnings.value) {
    earningsDistribution.technischePlanungDach.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualTechnischePlanungDachEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.technischePlanungDach.accountingStatusHistory.with(technischePlanungDachState.value, loggedInUser.loginName),
    )
  }

  val technischePlanungElektrikToSave = useMemo(technischePlanungElektrikState.value, manualTechnischePlanungElektrikEarnings.value) {
    earningsDistribution.technischePlanungElektrik.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualTechnischePlanungElektrikEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.technischePlanungElektrik.accountingStatusHistory.with(technischePlanungElektrikState.value, loggedInUser.loginName),
    )
  }

  val montageDachToSave = useMemo(montageDachState.value, manualMontageDachEarnings.value) {
    earningsDistribution.montageDach.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualMontageDachEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.montageDach.accountingStatusHistory.with(montageDachState.value, loggedInUser.loginName),
    )
  }

  val montageGeruestToSave = useMemo(montageGeruestState.value, manualMontageGeruestEarnings.value) {
    earningsDistribution.montageGeruest.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualMontageGeruestEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.montageGeruest.accountingStatusHistory.with(montageGeruestState.value, loggedInUser.loginName),
    )
  }

  val elektroInstallationToSave = useMemo(elektroInstallationState.value, manualElektroInstallationEarnings.value) {
    earningsDistribution.elektroInstallation.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualElektroInstallationEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.elektroInstallation.accountingStatusHistory.with(elektroInstallationState.value, loggedInUser.loginName),
    )
  }

  val netzvoranfrageToSave = useMemo(netzvoranfrageState.value, manualNetzvoranfrageEarnings.value) {
    earningsDistribution.netzvoranfrage.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualNetzvoranfrageEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.netzvoranfrage.accountingStatusHistory.with(netzvoranfrageState.value, loggedInUser.loginName),
    )
  }

  val neckarITToSave = useMemo(neckarITAccountingStatusState.value, manualNeckarITEarnings.value) {
    earningsDistribution.neckarITAccountingStatus.copy(
      manualEarningsHistory = earningsDistribution.tippgeber.manualEarningsHistory.with(manualNeckarITEarnings.value?.let { Money.euros(it) }, loggedInUser.loginName),
      accountingStatusHistory = earningsDistribution.neckarITAccountingStatus.accountingStatusHistory.with(neckarITAccountingStatusState.value, loggedInUser.loginName),
    )
  }

  val updatedEarningsDistribution = useMemo(
    tippgeberToSave,
    vertriebProjekterfassungToSave,
    vertriebAngebotsvorstellungToSave,
    technischePlanungDachToSave,
    technischePlanungElektrikToSave,
    montageDachToSave,
    montageGeruestToSave,
    elektroInstallationToSave,
    netzvoranfrageToSave,
    neckarITToSave,
  ) {
    earningsDistribution.copy(
      tippgeber = tippgeberToSave,
      vertriebProjekterfassung = vertriebProjekterfassungToSave,
      vertriebAngebotsvorstellung = vertriebAngebotsvorstellungToSave,
      technischePlanungDach = technischePlanungDachToSave,
      technischePlanungElektrik = technischePlanungElektrikToSave,
      montageDach = montageDachToSave,
      montageGeruest = montageGeruestToSave,
      elektroInstallation = elektroInstallationToSave,
      netzvoranfrage = netzvoranfrageToSave,
      neckarITAccountingStatus = neckarITToSave,
    ).also { newEarningsDistribution ->
      if (newEarningsDistribution != earningsDistribution) {
        UiActions.saveEarningsDistribution(
          projectId = projectPreview.projectId,
          configurationId = currentConfigurationPreview.configurationId,
          earningsDistribution = newEarningsDistribution,
        )
      }
    }
  }

  val updatedManualQuoteElements = useMemo(
    manualProfit1.value,
    manualProfit2Battery.value,
    manualProfit3Scaffolding.value,
    manualProfit4ElectricityMaterial.value,
    manualProfit5Assembly.value,
    manualProfit6ElectricityWork.value,
  ) {
    manualQuoteElements.copy(
      manualSumsForTags = buildMap {
        IncomePercentageCategory.entries.fastForEach { incomePercentageCategory ->
          val newManualSum = manualQuoteElementsMap[incomePercentageCategory]?.incomeState?.value?.let { Money.euros(it) }
          val newEditHistory = manualQuoteElements.manualSumsForTags[incomePercentageCategory]?.let { editHistory ->
            val oldManualSum = editHistory.currentValue
            if (newManualSum != oldManualSum) editHistory.with(newManualSum, loggedInUser.loginName) else editHistory
          } ?: PositionEditHistory(newManualSum, loggedInUser.loginName)
          put(incomePercentageCategory, newEditHistory)
        }
      },
    ).also { updatedManualQuoteElements ->
      if (updatedManualQuoteElements != manualQuoteElements) {
        UiActions.saveManualQuoteElements(
          projectId = projectPreview.projectId,
          configurationId = currentConfigurationPreview.configurationId,
          manualQuoteElements = updatedManualQuoteElements,
        )
      }
    }
  }


  val upToDateConfigurationPreview = currentConfigurationPreview.copy(
    earningsDistribution = updatedEarningsDistribution,
    manualQuoteElements = updatedManualQuoteElements,
  )

  val accountingTableEntriesList = buildList {
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.tippgeber, manualTippgeberEarnings, tippgeberState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.vertriebProjekterfassung, manualVertriebProjekterfassungEarnings, vertriebProjekterfassungState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.vertriebAngebotsvorstellung, manualVertriebAngebotsvorstellungEarnings, vertriebAngebotsvorstellungState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.technischePlanungDach, manualTechnischePlanungDachEarnings, technischePlanungDachState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.technischePlanungElektrik, manualTechnischePlanungElektrikEarnings, technischePlanungElektrikState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.montageDach, manualMontageDachEarnings, montageDachState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.montageGeruest, manualMontageGeruestEarnings, montageGeruestState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.elektroInstallation, manualElektroInstallationEarnings, elektroInstallationState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.netzvoranfrage, manualNetzvoranfrageEarnings, netzvoranfrageState))
    add(EarningsCompanyEntryAndStates(updatedEarningsDistribution.neckarITAccountingStatus, manualNeckarITEarnings, neckarITAccountingStatusState))
  }.groupBy { it.earningsCompanyEntry.companyProfile }.map {
    AccountingTableEntries(
      companyProfile = it.key,
      configurationPreview = upToDateConfigurationPreview,
      earningsCompanyEntries = it.value,
    )
  }.sortedByDescending { it.companyProfile }


  accountingTableEntriesList.fastForEach { accountingTableEntries ->
    val companyInformation = companyResolver[accountingTableEntries.companyProfile]
    val earningsCompanyEntries = accountingTableEntries.relevantEarningsCompanyEntries
    val openEarningsCompanyEntries = accountingTableEntries.openEarningsCompanyEntries
    val accountedEarningsCompanyEntries = earningsCompanyEntries.size - openEarningsCompanyEntries.size

    div("mb-3") {
      div("row") {
        div("col") {
          b { +companyInformation.name }
          span("ms-2") {
            attrs {
              addClass("text-center")
              addClass("align-middle")

              if (openEarningsCompanyEntries.isEmpty()) {
                addClass("text-success")
              } else if (accountedEarningsCompanyEntries > 0) {
                addClass("text-warning")
              } else {
                addClass("text-danger")
              }
            }

            if (openEarningsCompanyEntries.isEmpty()) {
              faCircleCheck()
            } else {
              b { +"${accountedEarningsCompanyEntries}/${earningsCompanyEntries.size}" }
            }
          }
        }
        div("col text-end") {
          +accountingTableEntries.relevantEarnings(howDoYouLikeYourQuoteElements).format(plannerI18nConfiguration)
        }
      }

      accountingTableEntries.relevantEarningsCompanyEntries.fastForEach { earningsCompanyEntryAndState ->
        val earningsCompanyEntry = earningsCompanyEntryAndState.earningsCompanyEntry
        val incomeState = earningsCompanyEntryAndState.incomeState
        val accountingStatusState = earningsCompanyEntryAndState.accountingStatusState
        val incomePercentage = when (earningsCompanyEntry) {
          is ResolvedCompanyEarningsEntry -> earningsCompanyEntry.incomePercentage
          is ResolvedNeckarITEarningsEntry -> upToDateConfigurationPreview.softwareIncomePercentage
        }

        div("row") {
          div("col my-auto") {
            checkbox(
              value = accountingStatusState.value == AccountingStatus.Accounted,
              onChange = { checked ->
                val setAsAccounted = { accountingStatusState.setter(if (checked) AccountingStatus.Accounted else AccountingStatus.Pending) }
                if (checked) {
                  setAsAccounted()
                } else {
                  confirm("Soll diese Abrechnung wirklich rückgängig gemacht werden?", setAsAccounted)
                }
              },
              fieldName = "${accountingTableEntries.configurationPreview.uuid}-${earningsCompanyEntry.companyProfile.companyCode}",
              title = incomePercentage.description,
            )
            span("form-text ms-3") {
              attrs {
                this.title = earningsCompanyEntry.accountingStatusHistory.currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"
              }
              +"${earningsCompanyEntry.accountingStatusHistory.currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"}: "
              +dateFormat.format(earningsCompanyEntry.accountingStatusHistory.lastEditedTime, plannerI18nConfiguration)
            }
          }

          val relevantEarnings = upToDateConfigurationPreview.getEarningsForIncomePercentage(incomePercentage, howDoYouLikeYourQuoteElements)
          div("col-5 text-end") {
            euroInputField(incomeState, "${projectPreview.uuid}-${earningsCompanyEntry.company}-${incomePercentage.description}", relevantEarnings)
            div("form-text") {
              +"${earningsCompanyEntry.manualEarningsHistory.currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"}: "
              +dateFormat.format(earningsCompanyEntry.manualEarningsHistory.lastEditedTime, plannerI18nConfiguration)
            }
          }
        }
      }
    }

    hr {}
  }

  manualQuoteElementsMap.values.toList().fastForEach { categoryAndState ->
    val incomePercentageCategory = categoryAndState.incomePercentageCategory
    val state = categoryAndState.incomeState
    val netPriceForCategory = upToDateConfigurationPreview.getNetPrice(incomePercentageCategory, howDoYouLikeYourQuoteElements)

    div("row") {
      div("col form-text my-auto") { +incomePercentageCategory.description }
      div("col-5 text-end") {
        euroInputField(state, "${projectPreview.uuid}-${incomePercentageCategory.name}", netPriceForCategory)
        updatedManualQuoteElements.manualSumsForTags[incomePercentageCategory]?.let { editHistoryEntry ->
          div("form-text") {
            +"${editHistoryEntry.currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"}: "
            +dateFormat.format(editHistoryEntry.lastEditedTime, plannerI18nConfiguration)
          }
        }
      }
    }
  }

  hr {}

  val upToDateQuoteElements = upToDateConfigurationPreview.quoteElements
  if (upToDateQuoteElements != null && upToDateQuoteElements.discountPercentage != 0.0.percent) {
    div("row") {
      div("col my-auto") { b { +"Rechnungssumme" } }
      div("col text-end") { b { +upToDateConfigurationPreview.getGrossPrice(howDoYouLikeYourQuoteElements).format(plannerI18nConfiguration) } }
    }
    div("row") {
      div("col my-auto") { +"Rabatt (${upToDateQuoteElements.discountPercentage.formatAsPercentage(0)})" }
      div("col text-end") { +upToDateQuoteElements.totalDiscount.format(plannerI18nConfiguration) }
    }
  }
  div("row") {
    div("col my-auto") { b { +"Nettosumme" } }
    div("col text-end") { b { +upToDateConfigurationPreview.getNetPrice(howDoYouLikeYourQuoteElements).format(plannerI18nConfiguration) } }
  }
}

private fun RDOMBuilder<DIV>.euroInputField(
  state: StateInstance<Double?>,
  fieldName: String,
  netPriceForCategory: Money?,
  mergeAbove: Boolean = false,
  mergeBelow: Boolean = false,
) {
  div("input-group") {
    nullableInputField(
      value = state.value?.toString(),
      onChange = { state.setter(it?.toDoubleOrNull()) },
      fieldName = fieldName,
      title = netPriceForCategory?.format(plannerI18nConfiguration) ?: "Nettopreis",
      placeholder = (netPriceForCategory?.euros ?: 0.00).format(i18nConfiguration = plannerI18nConfiguration),
      classes = "form-control float-end text-end",
    ) {
      attrs {
        type = InputType.number
        if (mergeAbove) mergedAbove()
        if (mergeBelow) mergedBelow()
      }
    }
    div("input-group-append") {
      span("input-group-text") { +"€" }
    }
  }
}

external interface AccountingTableProps : Props {
  var projectPreview: AccountingResolvedProjectPreview
  var currentConfigurationPreview: AccountingResolvedConfigurationPreview
  var howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements
}
