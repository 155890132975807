package it.neckar.lizergy.model.configuration.quote.builder

import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.MpptInputConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.StringConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import kotlinx.serialization.Serializable

@Serializable
data class ResolvedInverterConfiguration(
  val inverter: InverterWithStringInputs,
  override val inverterIndex: Int,
  override val mpptInputConfigurations: List<MpptInputConfiguration>,
) : InverterConfiguration {

  override val inverterId: InverterId
    get() = inverter.id

  fun updateStringConfigurationFor(inputIndex: Int, stringIndex: Int, update: StringConfiguration.() -> StringConfiguration): ResolvedInverterConfiguration {
    val updatedMpptInputConfigurations = mpptInputConfigurations.map { mpptInputConfiguration ->
      if (mpptInputConfiguration.inputIndex == inputIndex) {
        val updatedStringConfigurations = mpptInputConfiguration.stringConfigurations.map { stringConfiguration ->
          if (stringConfiguration.stringIndex == stringIndex) stringConfiguration.update() else stringConfiguration
        }
        mpptInputConfiguration.copy(stringConfigurations = updatedStringConfigurations)
      } else {
        mpptInputConfiguration
      }
    }
    return copy(mpptInputConfigurations = updatedMpptInputConfigurations)
  }

  fun forTheseRoofs(roofs: List<RoofId>): ResolvedInverterConfiguration {
    return copy(mpptInputConfigurations = mpptInputConfigurations.map { it.forTheseRoofs(roofs) })
  }

  fun format(): String {
    return buildString {
      append("Wechselrichter ${inverter.description} (${inverterIndex + 1})")
      mpptInputConfigurations.forEach { mpptInputConfiguration ->
        append("\n")
        append(mpptInputConfiguration.format())
      }
    }
  }

  companion object {
    operator fun invoke(inverter: InverterWithStringInputs, inverterIndex: Int): ResolvedInverterConfiguration {
      val mpptInputConfigurations = inverter.mppInputs.map { mppInput ->
        MpptInputConfiguration(mppInput.inputIndex, mppInput.strings.map { stringMppInput ->
          StringConfiguration(stringIndex = stringMppInput.stringIndex, optimalModuleCount = null, modulesStrings = null)
        })
      }
      return ResolvedInverterConfiguration(inverter = inverter, inverterIndex = inverterIndex, mpptInputConfigurations = mpptInputConfigurations)
    }
  }
}
