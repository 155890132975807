package components.project.configuration.layout

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterWithStringInputs
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*
import services.UiActions

val EditInverterConfiguration: FC<EditInverterConfigurationProps> = fc("EditInverterConfiguration") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val stringConfiguration = props::stringConfiguration.safeGet()
  val inverter = props::inverter.safeGet()
  val inverterIndex = props::inverterIndex.safeGet()
  val inputIndex = props::inputIndex.safeGet()
  val multipleStrings = props::multipleStrings.safeGet()
  val first = props::first.safeGet()
  val last = props::last.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val optimalModuleCount = useState(stringConfiguration.optimalModuleCount)


  useMemo(optimalModuleCount.value) {
    val updatesQuoteConfiguration = quoteConfiguration.copy(
      facilityConfiguration = quoteConfiguration.facilityConfiguration.updateStringConfigurationFor(
        inverter = inverter,
        inverterIndex = inverterIndex,
        inputIndex = inputIndex,
        stringIndex = stringConfiguration.stringIndex,
      ) {
        copy(optimalModuleCount = optimalModuleCount.value)
      }
    )

    UiActions.saveQuoteConfiguration(project, updatesQuoteConfiguration)
  }


  nullableFloatingIntInputField(
    valueAndSetter = optimalModuleCount,
    fieldName = uniqueIdMemo("string-${stringConfiguration.stringIndex}"),
    title = "Module (Optimal) [String ${stringConfiguration.stringIndex + 1}]",
    editableStatus = editableStatus,
  ) {
    attrs {
      if (multipleStrings == true) {
        if (last == false) mergedBelow()
        if (first == false) mergedAbove()
      }
    }
  }
}

external interface EditInverterConfigurationProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
  var stringConfiguration: InverterConfiguration.StringConfiguration
  var inverter: InverterWithStringInputs
  var inverterIndex: Int
  var inputIndex: Int
  var multipleStrings: Boolean?
  var first: Boolean?
  var last: Boolean?
  var editableStatus: EditableStatus
}

